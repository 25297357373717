import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import sharedStyles from '../../styles/global'
import ImageOverlayWithCaption from '../imageOverlayWithCaption'
import ImageOverlay from '../imageOverlay'
import Caption from '../caption'
import TextBlock from './textBlock'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  sideBySideImages: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridGap: '15px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      marginLeft: '20px',
      marginRight: '20px',
    },
  },
}))

const SideBySide = ({ css, article }) => {
  const theme = useTheme()
  const localCss = useStyles(theme)
  const showCaption = false

  const sideBySideImageSettings = {
    crop: true,
    container: {
      maxHeight: '473px',
    },
    image: {
      height: '237px',
    },
    overlay: { background: '' },
  }
  const cssSettings = {
    crop: true,
    container: {
      maxHeight: '400px',
    },
    image: {},
    overlay: { background: '' },
  }

  return (
    <>
      <TextBlock subHeading={article.SubHeading1} text={article.Text1.data.Text1} />
      {article.image1 && article.image2 && (
        <div className={css.imageSpacing}>
          <div className={localCss.sideBySideImages}>
            <div>
              <ImageOverlay
                alt={article.image1.Alt}
                css={sideBySideImageSettings}
                image={article.image1.File}
                showCaption={showCaption}
              />
            </div>
            <div>
              <ImageOverlay
                alt={article.image2.Alt}
                css={sideBySideImageSettings}
                image={article.image2.File}
                showCaption={showCaption}
              />
            </div>
          </div>
          <div className={localCss.sideBySideImages}>
            <Caption credit={article.image1.Credit} text={`(First) ${article.image1.Caption}`} />
            <Caption credit={article.image2.Credit} text={`(Second) ${article.image2.Caption}`} />
          </div>
        </div>
      )}

      <TextBlock subHeading={article.SubHeading2} text={article.Text2.data.Text2} />
      {article.image3 && (
        <div className={css.imageSpacing}>
          <ImageOverlayWithCaption
            alt={article.image3.Alt}
            caption={article.image3.Caption}
            credit={article.image3.Credit}
            css={cssSettings}
            image={article.image3.File}
            showCaption={showCaption}
          />
        </div>
      )}

      <TextBlock subHeading={article.SubHeading3} text={article.Text3.data.Text3} />
      {article.image4 && (
        <div className={css.imageSpacing}>
          <ImageOverlayWithCaption
            alt={article.image4.Alt}
            caption={article.image4.Caption}
            credit={article.image4.Credit}
            css={cssSettings}
            image={article.image4.File}
            showCaption={showCaption}
          />
        </div>
      )}
      <TextBlock subHeading={article.SubHeading4} text={article.Text4.data.Text4} />
      <TextBlock subHeading={article.SubHeading5} text={article.Text5.data.Text5} />
    </>
  )
}

export default SideBySide
