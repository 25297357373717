import React from 'react'
import {
  Button,
  Grid,
  Container,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
  Checkbox,
} from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import axios from 'axios'
import Alert from '@material-ui/lab/Alert'
import Helmet from 'react-helmet'
import Recaptcha from 'react-recaptcha'
import sharedStyles from '../styles/global'
import CssTextField from './cssTextField'
import Modal from './modal'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
}))

export default function ContactUs() {
  function validateEmail(email) {
    const re = /\S+@\S+\.\S+/
    const isValid = re.test(email.target.value)
    isValid ? setShowMsg(false) : setShowMsg(true)
  }
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  const [openIsLoading, setOpenIsLoading] = React.useState(false)
  const handleCloseIsLoading = () => {
    setOpenIsLoading(false)
  }
  const [OpenisError, setOpenIsError] = React.useState(false)
  const handleCloseIsError = () => {
    setOpenIsError(false)
  }
  const [open, setOpen] = React.useState(false)
  const [showMsg, setShowMsg] = React.useState(false)
  const [userMsg, setUserMsg] = React.useState('')
  const theme = useTheme()
  const classes = useStyles(theme)
  const [category, SetCategory] = React.useState('General')
  const [OrgType, SetOrgType] = React.useState('')
  const [isCapchaLoaded, setIsCapchaLoaded] = React.useState(false)
  const [isVerified, setIsVerified] = React.useState(false)
  const [SessionBool, SetSessionBool] = React.useState(false)
  const [successMsg, setSuccessMsg] = React.useState('')

  const recaptchaLoaded = () => {
    setIsCapchaLoaded(true)
  }
  const expCallBack = () => {
    setIsVerified(false)
  }
  const verifyCallback = () => {
    setIsVerified(true)
  }

  const handleSubmit = (event) => {
    event.preventDefault()
    const form = new FormData(event.target)
    const firstName = form.get('firstName')
    const lastName = form.get('lastName')
    const name = `${firstName} ${lastName}`
    setUserMsg(`Thank you ${name}`)
    const organization = form.get('organization')
    const OrganizationType = form.get('OrganizationType')
    const email = form.get('email')
    const message = form.get('message')
    if (showMsg || !isCapchaLoaded || !isVerified || (category == 'Industry' && OrgType == '')) {
      return
    }
    setOpenIsLoading(true)

    const current = new Date()
    const timeStamp = `${current.getMonth() + 1}/${current.getDate()}/${current.getFullYear()} ${current.getHours()}:${current.getMinutes()}:${current.getSeconds()}`
    let url
    let payload

    setSuccessMsg(`Thank you, ${name}. Your form has been successfully submitted.`)

    if (category == 'Partnership') {
      payload = {
        firstName,
        lastName,
        email,
        phone: form.get('Phone'),
        institutionName: form.get('organization'),
        institutionType: OrganizationType,
        reasoning: message,
        session: SessionBool,
        createdDt: timeStamp,
      }
      url = '/api/CIS/CISRequest'
    } else {
      payload = {
        name: `${firstName} ${lastName}`,
        message,
        email,
        organization,
      }
      url = '/api/ContactUs/SendEmailContactUs'
    }

    axios
      .post(url, payload)
      .then((response) => {
        // Handle success
        setOpenIsLoading(false)
        setOpen(true)
      })
      .catch((error) => {
        setOpenIsLoading(false)
        setOpenIsError(true)
      })

    // event.target.reset();
  }

  const [showRecaptcha, setShowRecaptcha] = React.useState(false)

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowRecaptcha(true)
    }, 500) // Adjust the delay as needed

    return () => {
      clearTimeout(timer)
      setShowRecaptcha(false)
    }
  }, [])

  return (
    <Container component="main" maxWidth="md">
      <Helmet>
        <script async defer src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit" />
      </Helmet>
      <form className={classes.form} id="ContactUsform" onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <CssTextField
              autoComplete="fname"
              autoFocus
              fullWidth
              id="firstName"
              label="First Name"
              name="firstName"
              required
              variant="outlined"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CssTextField
              autoComplete="lname"
              fullWidth
              id="lastName"
              label="Last Name"
              name="lastName"
              required
              variant="outlined"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CssTextField
              autoComplete="organization"
              fullWidth
              id="organization"
              label="Organization Name"
              name="organization"
              required
              variant="outlined"
            />
          </Grid>
          <Grid item sm={6} xs={12}>
            <CssTextField
              autoComplete="email"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              required
              variant="outlined"
              onChange={(e) => {
                validateEmail(e)
              }}
            />
            <Alert icon={false} severity="warning" style={{ display: showMsg ? 'block' : 'none', color: 'red' }}>
              Email is not valid!
            </Alert>
          </Grid>
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <FormLabel component="legend">Type of Inquiry</FormLabel>
              <RadioGroup
                aria-label="position"
                defaultValue="top"
                name="position"
                row
                onChange={(e) => SetCategory(e.target.value)}
              >
                <FormControlLabel
                  control={<Radio checked={category == 'General'} color="primary" label="General" />}
                  id="General"
                  label="General"
                  value="General"
                />
                <FormControlLabel
                  control={<Radio color="primary" />}
                  id="Partnership"
                  label="Partnership"
                  value="Partnership"
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          {category == 'Partnership' && (
            <>
              <Grid item sm={6} xs={12}>
                <CssTextField autoComplete="Phone" fullWidth id="Phone" label="Phone" name="Phone" variant="outlined" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <CssTextField
                  autoComplete="OrganizationType"
                  fullWidth
                  id="OrganizationType"
                  label="Organization Type"
                  name="OrganizationType"
                  required
                  select
                  value={OrgType}
                  variant="outlined"
                  onChange={(e) => {
                    SetOrgType(e.target.value)
                  }}
                >
                  <MenuItem key="Industry" style={{ whiteSpace: 'normal' }} value="Industry">
                    Industry
                  </MenuItem>
                  <MenuItem key="Government" style={{ whiteSpace: 'normal' }} value="Government">
                    Government
                  </MenuItem>
                  <MenuItem key="Academia" style={{ whiteSpace: 'normal' }} value="Academia">
                    Academia
                  </MenuItem>
                  <MenuItem key="International" style={{ whiteSpace: 'normal' }} value="International">
                    International
                  </MenuItem>
                </CssTextField>
              </Grid>
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        id="Session"
                        name="Session"
                        value={SessionBool}
                        onChange={(e) => SetSessionBool(e.target.checked)}
                      />
                    }
                    label="Would you like to schedule an industry session with Commercialization, Innovation, and Synergies (CIS) representatives to discuss the request and learn more about CIS?"
                  />
                </FormControl>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <CssTextField
              fullWidth
              id="message"
              label="Message"
              multiline
              name="message"
              required
              rows={6}
              variant="outlined"
            />
          </Grid>
        </Grid>
        {showRecaptcha && (
          <Grid container spacing={2}>
            <Grid item sm={6} style={{ marginTop: 5 }} xs={12}>
              <Recaptcha
                expiredCallback={expCallBack}
                id="recaptcha-contactUs"
                onloadCallback={recaptchaLoaded}
                render="explicit"
                sitekey="6LfFxL4ZAAAAAGeU0rcZwrwD7InxYOu7kUx1AK-H"
                verifyCallback={verifyCallback}
              />
            </Grid>
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid item sm={6} xs={12}>
            <Button className={classes.submit} color="primary" fullWidth type="submit" variant="contained">
              {' '}
              Send Message
            </Button>
          </Grid>
        </Grid>
      </form>
      <Modal handleClose={handleClose} isOpen={open} title={userMsg}>
        <p>We have recieved your message</p>
      </Modal>
      <Modal handleClose={handleClose} isOpen={open} title={successMsg}>
        <h4>Your form has been successfully submitted.</h4>
        {/* <h4 style={{ marginTop: 5 }}> Please expect a response {valUrgencySub}.</h4> */}
      </Modal>
      <Modal
        // title={'Submitting please wait'}
        handleClose={handleCloseIsLoading}
        isOpen={openIsLoading}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '20px',
          borderRadius: '10px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          backgroundColor: '#fff',
          color: '#333',
          fontFamily: '"Helvetica Neue", sans-serif',
        }}
      >
        <h2 style={{ margin: '0 0 20px 0' }}>Submitting, please wait...</h2>
        <CircularProgress style={{ alignSelf: 'center' }} />
      </Modal>

      <Modal handleClose={handleCloseIsError} isOpen={OpenisError}>
        <h4>Something went wrong, please try again later.</h4>
      </Modal>
    </Container>
  )
}
