import React from 'react'
import { useTheme, makeStyles, useMediaQuery } from '@material-ui/core'
import { AccessTime } from '@material-ui/icons'
import VerticalAlignBottomIcon from '@material-ui/icons/VerticalAlignBottom'
import Img from 'gatsby-image'
import sharedStyles from '../styles/global'
import Modal from './collateralModal'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  wrapper: {
    display: 'grid',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
  },
  modalImage: {
    width: '100%',
    minWidth: '400px',
    [theme.breakpoints.down('sm')]: {
      width: '280px',
      minWidth: '255px',
    },
  },

  downloadButton: {
    // padding: theme.spacing(1.3, 6),
    padding: theme.spacing(1.3, 1),
    borderRadius: '8px',
    color: 'white',
    backgroundColor: '#1D3787',
    cursor: 'pointer',
    textDecoration: 'none',
  },
  downloadButtonContainer: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
  },
  downloadButtonText: {
    textAlign: 'left',
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(5),
  },
  downloadButtonIcon: {
    paddingRight: theme.spacing(0),
    fontSize: '1.2rem',
  },
  item: {
    marginRight: '20px',
    marginBottom: '20px',
  },
  itemImage: {
    borderRadius: '10px',
    height: '200px',
    maxHeight: '200px',
  },
  cursor: {
    cursor: 'pointer',
  },
  blurbSpacing: {
    marginTop: '1.0rem',
    marginBottom: '2rem',
    maxHeight: '160px',
    overflowY: 'auto',
  },
}))

const Collateral = (props) => {
  const theme = useTheme()
  const css = useStyles(theme)

  const { items } = props
  const columns = setColumns()
  const [selectedPaper, setSelectedPaper] = React.useState(null)
  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  function setColumns() {
    let columns = 4
    if (useMediaQuery(theme.breakpoints.down('sm'))) {
      columns = 1
    }
    if (useMediaQuery(theme.breakpoints.up('sm'))) {
      columns = 2
    }
    if (useMediaQuery(theme.breakpoints.up('md'))) {
      columns = 3
    }
    if (useMediaQuery(theme.breakpoints.up('lg'))) {
      columns = 4
    }
    return columns
  }

  const handlePaperClick = (paper, event) => {
    setSelectedPaper(paper)
    setOpen(true)
  }

  return (
    <div className={css.wrapper}>
      {items &&
        items.map((item, index) => {
          console.log(`item: ${item}`)
          console.log(`items: ${items}`)
          const thumbnail = item.Thumbnail
            ? item.Thumbnail.localFile.childImageSharp.fluid
            : console.log('no thumbnail') // change this line later to data....
          return (
            <div className={css.item} key={`item-${index}`}>
              <figure>
                <div onClick={() => handlePaperClick(item)}>
                  <Img className={[css.itemImage, css.cursor].join(' ')} fluid={thumbnail} />
                </div>
                <figcaption>
                  <div className={[css.sansProBold, css.pt18, css.bold, css.titleSpacing, css.pointer].join(' ')}>
                    {item.Title}
                  </div>

                  <div
                    className={[css.openSans, css.pt16, css.italic].join(' ')}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <AccessTime style={{ fontSize: '12px' }} />
                    &nbsp;
                    {item.Publish}
                  </div>
                </figcaption>
              </figure>
            </div>
          )
        })}
      {selectedPaper && (
        <Modal handleClose={handleClose} isOpen={open} publish={selectedPaper.Publish} title={selectedPaper.Title}>
          <Img
            alt={selectedPaper.Title}
            className={css.modalImage}
            fluid={selectedPaper.Thumbnail.localFile.childImageSharp.fluid}
          />
          <div className={[css.pt20, css.opacity90, css.blurbSpacing].join(' ')}>{selectedPaper.Blurb}</div>
          <div className={css.downloadButtonContainer}>
            <a
              className={css.downloadButton}
              href={selectedPaper.File.localFile.publicURL}
              rel="noreferrer"
              target="_blank"
            >
              <span className={css.downloadButtonText}>Download</span>
              <VerticalAlignBottomIcon className={css.downloadButtonIcon} />
            </a>
          </div>
        </Modal>
      )}
    </div>
  )
}

export default Collateral
