import React from 'react'
import { graphql } from 'gatsby'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import PartnershipCard from '../components/PartnershipCard'
import sharedStyles from '../styles/global'
import SEO from '../components/seo'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  projectsContainer: {
    marginTop: '25px',
    display: 'grid',
    gridAutoRows: 'auto',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr 1fr 1fr 1fr',
    },
    gridGap: '10px',
  },
}))

const ProjectMenu = ({ data }) => {
  const theme = useTheme()
  const css = useStyles(theme)

  const styles = [css.content, css.projectsContainer].join(' ')
  let columns = 4
  if (useMediaQuery(theme.breakpoints.down('sm'))) {
    columns = 1
  }
  if (useMediaQuery(theme.breakpoints.up('sm'))) {
    columns = 2
  }
  if (useMediaQuery(theme.breakpoints.up('md'))) {
    columns = 3
  }
  if (useMediaQuery(theme.breakpoints.up('lg'))) {
    columns = 4
  }

  return (
    <Layout>
      <SEO
        description="Partnerships - ESC Public Site"
        keywords="ADVANCED COMMUNICATIONS CAPABILITIES FOR EXPLORATION AND SCIENCE SYSTEMS (ACCESS),COMMERCIALIZATION, INNOVATION, AND SYNERGIES OFFICE (CIS),INTEGRATED LCRD LOW-EARTH ORBIT USER MODEM AND AMPLIFIER TERMINAL (ILLUMA-T),INTEGRATED STRATEGIC PRODUCTS, INFORMATION, AND RESOURCES ENTERPRISE (INSPIRE),LASER COMMUNICATION RELAY DEMONSTRATION (LCRD),LASER ENHANCED MISSION COMMUNICATIONS NAVIGATION AND OPERATIONAL SERVICES PIPELINE (LEMNOS),LUNAR COMMUNICATIONS RELAY AND NAVIGATION SYSTEMS (LCRNS),NEAR SPACE NETWORK (NSN),TECHNOLOGY ENTERPRISE AND MISSION PATHFINDER OFFICE (TEMPO),SEARCH AND RESCUE (SAR)"
        title="Partnerships ESC"
      />
      <Helmet />
      <div className={styles}>
        {data.projects.nodes.map((project, index) => (
          <div key={`project_${index}`}>
            <PartnershipCard project={project} />
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default ProjectMenu

export const pageQuery = graphql`
  query PartnershipQuery {
    projects: allStrapiProject(
      filter: { Acronym: { in: ["DC-QNet", "XGEO-METRO"] } }
      sort: { order: ASC, fields: Name }
    ) {
      nodes {
        Acronym
        ShowAcronym
        Color
        Name
        Image {
          id
          localFile {
            childImageSharp {
              fluid(maxHeight: 150) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
