import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, useMediaQuery } from '@material-ui/core'
import Img from 'gatsby-image'
import sharedStyles from '../styles/global'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  journalDiv: {
    fontSize: '11px',
    fontWeight: 'bold',
    textAlign: 'center',
    paddingRight: '20px',
    paddingTop: '20px',
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0px',
      paddingTop: '20px',
      justifyContent: 'center',
    },
  },
  flexBox: {
    display: 'flex',
    flexWrap: 'wrap',
    [theme.breakpoints.down('sm')]: {
      display: 'grid',
      maxWidth: '350px',
      justifyContent: 'center',
    },
  },
  roundedCorner: {
    borderRadius: '5px',
    maxWidth: '285px',
  },
}))

const GridImages = (props) => {
  const theme = useTheme()
  const css = useStyles(theme)

  const { gridImages, color } = props

  let columns = 4
  let rows = 4
  if (useMediaQuery(theme.breakpoints.down('950'))) {
    columns = 3
    rows = 5
  }
  if (useMediaQuery(theme.breakpoints.down('700'))) {
    columns = 2
    rows = 8
  }
  if (useMediaQuery(theme.breakpoints.down('xs'))) {
    columns = 1
    rows = 15
  }

  return (
    <div className={css.flexBox}>
      {gridImages.map((grdImg, idx) =>
        grdImg.Alt == '--multimedia' ? (
          <div className={css.journalDiv}>
            <a className={css.aLink} href={grdImg.Credit}>
              <img
                alt={grdImg.Title}
                className={css.roundedCorner}
                src={grdImg.File.localFile.childImageSharp.fluid.src}
              />
              <br />
              {grdImg.Caption}
            </a>
          </div>
        ) : (
          ''
        )
      )}
    </div>
  )
}

export default GridImages
