import React, { useState, useEffect } from 'react'

import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import {
  makeStyles,
  useTheme,
  useMediaQuery,
  Box,
  Tab,
  Tabs,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Markdown from 'react-markdown/with-html'
import Layout from '../components/layout'
import SEO from '../components/seo'
import sharedStyles from '../styles/global'
import ImpactCardGrid from '../components/impactCardGrid'
import ImageWithCaption from '../components/imageWithCaption'
import ImageHeader from '../components/imageHeader'
import WithLocation from '../components/withLocation'
import { getQueryStringParams, determineActiveTab, determineInitialTab } from '../services/utilityService'

function getQueryVariable(variable) {
  if (typeof window !== 'undefined') {
    const query = window.location.search.substring(1)
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split('=')
      if (pair[0] == variable) {
        return pair[1]
      }
    }
    return false
  }
}

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  aboutMainHeader: {
    margin: '3rem 0rem 0.3rem 0rem',
  },
  aboutTabHeader: {
    marginTop: '1.2rem',
    marginBottom: '1.2rem',
  },
  aboutBodyText: {
    marginBottom: '1.5rem',
  },
  featureSection: {
    display: 'grid',
    gridGap: '40px',
    marginTop: '25px',
    marginBottom: '25px',
    gridTemplateColumns: '1fr',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
    '& div': {
      marginTop: '45px',
      marginBottom: '45px',
    },
  },
  infoImage: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingTop: '30px',
    paddingBottom: '25px',
    height: '200px',
  },
  paragraphSpacing: {
    marginTop: '30px',
    marginBottom: '30px',
  },
  panel: {
    boxShadow: 'none',
    borderBottom: 'solid 1px #e4e4e4',
    '&.MuiExpansionPanel-root:before': {
      content: 'none',
    },
  },
  panelDetails: {
    padding: theme.spacing(0, 3, 0, 3),
  },
  ulStyling: {
    paddingLeft: '25px',
  },
  techPanelSubSection: {
    margin: theme.spacing(0, 0, 4, 0),
    '& h4': {
      margin: theme.spacing(0, 0, 2.5, 0),
    },
  },
  strongStatementSpacing: {
    margin: theme.spacing(0, 0, 2, 0),
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 0, 5, 0),
    },
  },
  bottomSpacing2: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  bottomSpacing6: {
    margin: theme.spacing(0, 0, 6, 0),
  },
}))

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      aria-labelledby={`scrollable-auto-tab-${index}`}
      component="div"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      <Box mt={4}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}
function expandPanel(panel) {
  const panelName = getQueryVariable('panel')
  let rtn = false
  panel == panelName ? (rtn = true) : (rtn = false)
  return rtn
}

const About = (props) => {
  const { data, location } = props
  const pageContent = data.allAboutPageJson.nodes[0]
  const tabNames = [
    pageContent.overview.section1.header,
    pageContent.networks.header,
    pageContent.technology.header,
    pageContent.impact.header,
    pageContent.history.header,
  ]

  const [activeTab, setActiveTab] = useState(() => determineInitialTab(props.location.href, tabNames))

  const images = data.images.nodes.reduce((map, image) => {
    map[image.name] = image
    return map
  }, {})

  pageContent.impact.impactCards.map((impact) => {
    impact.image = null
    let imageNode = data.horizontal.nodes.find((node) => node.childImageSharp.fixed.originalName === impact.photoName)
    if (imageNode) {
      impact.image = imageNode.childImageSharp.fixed
    } else {
      imageNode = data.vertical.nodes.find((node) => node.childImageSharp.fixed.originalName === impact.photoName)
      if (imageNode) {
        impact.image = imageNode.childImageSharp.fixed
      }
    }
  })

  const theme = useTheme()
  const css = useStyles(theme)

  const paragraphBaseStyles = [css.aboutBodyText, css.pt22, css.openSans, css.opacity90].join(' ')
  const strongStatement = [css.strongStatementSpacing, css.sansProBold, css.pt35].join(' ')
  const headerStyles = [css.pt40, css.sansProSemiBold, css.uppercase, css.aboutTabHeader].join(' ')

  const url = props.location.href || ''
  const queryParams = getQueryStringParams(url)

  function handleChange(evt, newTabIndex) {
    setActiveTab(newTabIndex)
    const newTabParamValue = tabNames[newTabIndex].toLowerCase()
    if (typeof window !== 'undefined') {
      const newURL = `${window.location.origin}${window.location.pathname}?tab=${encodeURIComponent(newTabParamValue)}`
      window.history.pushState({ path: newURL, tabIndex: newTabIndex }, null, newURL)
    }
    setTabParam(newTabParamValue)
  }

  if (typeof window !== 'undefined') {
    window.addEventListener('popstate', (data) => {
      const { state } = data
      if (state && state.tabIndex >= 0) {
        setActiveTab(data.state.tabIndex)
      } else {
        setActiveTab(0)
      }
    })
  }

  const [expandedPanel, setExpandedPanel] = React.useState(false)

  const handleTechPanelChange = (panel) => (event, isExpanded) => {
    setExpandedPanel(isExpanded ? panel : false)
  }

  const [tabParam, setTabParam] = useState('')

  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const firstFeatureStyles = [css.center]
  const secondFeatureStyles = [css.center]
  const firstFeatureClasses = firstFeatureStyles.join(' ')
  const secondFeatureClasses = secondFeatureStyles.join(' ')
  const ourVisionBgImage = data.ourVision // images['mission-vision']

  useEffect(() => {
    determineActiveTab(queryParams.tab, tabNames, setTabParam, setActiveTab)
  }, [tabParam])

  return (
    <Layout>
      <SEO description="About - ESC Public Site" keywords="About - ESC Public Site (ESC)" title="About ESC" />
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>
      <div>
        <ImageHeader image={data.headerImage} title="About ESC" />
        <div className={css.content}>
          <Tabs
            aria-label="scrollable auto tabs example"
            indicatorColor="primary"
            scrollButtons="auto"
            textColor="primary"
            value={activeTab}
            variant="scrollable"
            onChange={handleChange}
          >
            {tabNames &&
              tabNames.map((tabName, idx) => {
                const tabStyles = [css.tabControl, css.sansProSemiBold]
                if (idx !== activeTab) {
                  tabStyles.push(css.nonActiveTab)
                }
                const tabClasses = tabStyles.join(' ')
                return <Tab key={`${tabName}-tab-${idx}`} label={tabName} {...a11yProps(idx)} className={tabClasses} />
              })}
          </Tabs>
        </div>
        <TabPanel index={0} value={activeTab}>
          <div className={css.content}>
            <h2 className={headerStyles}>{pageContent.overview.section1.header}</h2>
            <Markdown
              className={paragraphBaseStyles}
              escapeHtml={false}
              source={pageContent.overview.section1.paragraphs[0]}
            />
            <Markdown
              className={strongStatement}
              escapeHtml={false}
              source={pageContent.overview.section1.paragraphs[1]}
            />
          </div>
          <div
            className={[css.fadedBlueBg, css.bottomSpacing6].join(' ')}
            style={{
              backgroundImage: `url(${data.ourVision.childImageSharp.fluid.src})`,
              color: '#ffffff',
              backgroundSize: 'cover',
              overflow: 'hidden',
            }}
          >
            <div className={[css.content, css.featureSection].join(' ')}>
              {pageContent.overview.section2 &&
                pageContent.overview.section2.map((info, infoIndex) => (
                  <div key={`overview-${infoIndex}`}>
                    <h3 className={[css.sansProBold, css.uppercase, css.pt35, css.bottomSpacing2].join(' ')}>
                      {info.header}
                    </h3>
                    <p className={[css.pt20, css.openSans, css.italic].join(' ')} key={`overview-p-${infoIndex}`}>
                      {info.text}
                    </p>
                  </div>
                ))}
            </div>
          </div>
          <div className={css.content}>
            <h3 className={css.aboutTabHeader}>{pageContent.overview.section3.header}</h3>
            <div className={paragraphBaseStyles}>
              <Markdown
                className={paragraphBaseStyles}
                escapeHtml={false}
                key="section3"
                source={pageContent.overview.section3.text}
              />
            </div>
          </div>
        </TabPanel>
        <TabPanel index={1} value={activeTab}>
          <div className={css.content}>
            <h2 className={headerStyles}>{pageContent.networks.header}</h2>
            {pageContent.networks.paragraphs &&
              pageContent.networks.paragraphs.map((info, networkIndex) => (
                <Markdown
                  className={paragraphBaseStyles}
                  escapeHtml={false}
                  key={`network-paragraph-${networkIndex}`}
                  source={info}
                />
              ))}
          </div>
        </TabPanel>
        <TabPanel index={2} value={activeTab}>
          <div className={[css.content].join(' ')}>
            <h2 className={headerStyles}>{pageContent.technology.header}</h2>
            {pageContent.technology.paragraphs &&
              pageContent.technology.paragraphs.map((info, pIndex) => (
                <div className={paragraphBaseStyles} key={`tech-paragraph-${pIndex}`}>
                  <Markdown className={`tech-info-${pIndex}`} escapeHtml={false} source={info} />
                </div>
              ))}
            <div className={strongStatement}>{pageContent.technology.technologies.header}</div>
            {pageContent.technology.technologies.sections &&
              pageContent.technology.technologies.sections.map((tech, tIndex) => (
                <ExpansionPanel
                  className={css.panel}
                  defaultExpanded={expandPanel(`tech-panel-${tIndex}`)}
                  key={`tech-panel-${tIndex}`}
                  onChange={handleTechPanelChange(`tech-panel-${tIndex}`)}
                >
                  <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                    <h3 className={[css.pt30, css.bold, css.capitalize].join(' ')}>{tech.header}</h3>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <div className={[css.panelDetails].join(' ')}>
                      {tech.subsections &&
                        tech.subsections.map((section, sIndex) => (
                          <div className={css.techPanelSubSection} key={`tect-subsection-${sIndex}`}>
                            <h4 className={[css.pt26, css.regular, css.bold].join(' ')}>{section.header}</h4>
                            {section.paragraphs &&
                              section.paragraphs.map((paragraph, pIndex) => (
                                <div className={paragraphBaseStyles} key={`paragraph-${sIndex}-${pIndex}`}>
                                  <Markdown escapeHtml={false} source={paragraph} />
                                </div>
                              ))}
                            {section.subHeader && (
                              <h5 className={[css.pt20, css.regular].join(' ')}>{section.subHeader}</h5>
                            )}
                            {section.ul && (
                              <ul className={css.ulStyling}>
                                {section.ul.map((listItem, i) => (
                                  <li key={`listItem-${sIndex}-${i}`}>
                                    <Markdown escapeHtml={false} source={listItem} />
                                  </li>
                                ))}
                              </ul>
                            )}
                            {section.image && (
                              <div className={css.bottomSpacing2}>
                                <ImageWithCaption
                                  alt={section.caption}
                                  caption={section.caption}
                                  credit={section.credit}
                                  fluidImage={images[section.image].childImageSharp.fluid}
                                />
                              </div>
                            )}
                            {section.paragraphs2 &&
                              section.paragraphs2.map((paragraph, pIndex) => (
                                <div className={paragraphBaseStyles} key={`paragraph2-${sIndex}-${pIndex}`}>
                                  <Markdown
                                    className={paragraphBaseStyles}
                                    escapeHtml={false}
                                    key={`paragraph2-paragraph-${sIndex}-${pIndex}`}
                                    source={paragraph}
                                  />
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              ))}
          </div>
        </TabPanel>
        <TabPanel index={3} value={activeTab}>
          <div className={css.content}>
            <h2 className={headerStyles}>{pageContent.impact.header}</h2>
            {pageContent.impact.paragraphs &&
              pageContent.impact.paragraphs.map((pgraph, idx) => (
                <div className={paragraphBaseStyles} key={`impact-paragraph-${idx}`}>
                  {pgraph.join(' ')}
                </div>
              ))}
          </div>
          <ImpactCardGrid impactCards={pageContent.impact.impactCards} />
        </TabPanel>
        <TabPanel index={4} value={activeTab}>
          <div className={css.content}>
            <h2 className={headerStyles}>{pageContent.history.header}</h2>
            {pageContent.history.groups &&
              pageContent.history.groups.map((group, index) => {
                let image = null
                if (group.image) {
                  image = images[group.image.name]
                }
                return (
                  <div key={`historyGroup-${index}`}>
                    {image && (
                      <div>
                        <ImageWithCaption
                          alt={group.image.caption}
                          caption={group.image.caption}
                          credit={group.image.credit}
                          fluidImage={image.childImageSharp.fluid}
                        />
                      </div>
                    )}

                    {group.paragraphs.map((paragraph, pIndex) => (
                      <div className={paragraphBaseStyles} key={`history-${index}-${pIndex}`}>
                        <Markdown
                          className={paragraphBaseStyles}
                          escapeHtml={false}
                          key={`paragraph2-history-${index}-${pIndex}`}
                          source={paragraph}
                        />
                      </div>
                    ))}

                    {group.ul && (
                      <ul className={paragraphBaseStyles}>
                        {group.ul.map((hlistItem, i) => (
                          <li key={`history-itm-${i}`}>
                            <Markdown escapeHtml={false} source={hlistItem} />
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                )
              })}
          </div>
        </TabPanel>
      </div>
    </Layout>
  )
}

export default WithLocation(About)

export const pageQuery = graphql`
  query AboutQuery {
    allAboutPageJson {
      nodes {
        id
        overview {
          section1 {
            header
            paragraphs
          }
          section2 {
            header
            image
            alt
            text
          }
          section3 {
            header
            text
          }
        }
        networks {
          header
          paragraphs
        }
        technology {
          header
          paragraphs
          technologies {
            header
            sections {
              header
              subsections {
                header
                paragraphs
                paragraphs2
                image
                caption
                credit
                subHeader
                ul
              }
            }
          }
        }
        impact {
          header
          paragraphs
          impactCards {
            title
            text
            photoName
            alt
          }
        }
        history {
          header
          groups {
            image {
              name
              caption
              credit
            }
            paragraphs
          }
        }
      }
    }
    horizontal: allFile(
      filter: { dir: { glob: "**/images/impacts" }, childImageSharp: { fixed: { aspectRatio: { gt: 1 } } } }
    ) {
      nodes {
        childImageSharp {
          fixed(cropFocus: CENTER, fit: COVER, height: 310) {
            ...GatsbyImageSharpFixed
            originalName
          }
        }
      }
    }
    vertical: allFile(
      filter: { dir: { glob: "**/images/impacts" }, childImageSharp: { fixed: { aspectRatio: { lte: 1 } } } }
    ) {
      nodes {
        childImageSharp {
          fixed(fit: COVER, cropFocus: CENTER, width: 310) {
            ...GatsbyImageSharpFixed
            originalName
          }
        }
      }
    }
    images: allFile(filter: { dir: { glob: "**/images/**" } }) {
      nodes {
        name
        publicURL
        ext
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
    ourVision: file(ext: { eq: ".jpg" }, name: { eq: "mission-vision" }) {
      name
      publicURL
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headerImage: file(relativePath: { eq: "headers/about.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
