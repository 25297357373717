import React, { useState, useEffect } from 'react'

import { makeStyles, useTheme, Box, Typography, Paper } from '@material-ui/core'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { AccessTime } from '@material-ui/icons'
import Layout from '../components/layout'
import SubMenu from '../components/peopleSubMenus'
import sharedStyles from '../styles/global'
import SEO from '../components/seo'
import ImageHeader from '../components/imageHeader'
import WithLocation from '../components/withLocation'

import ProfileImg from '../components/profileImg'
import { getQueryStringParams, determineActiveTab, determineInitialTab } from '../services/utilityService'
import PeopleCarousel from '../components/PeopleCarousel'
import { filterArticles, findImage } from '../services/articleService'

function TabPanel(props) {
  const { children, value, index, ...other } = props
  return (
    <Typography
      aria-labelledby={`scrollable-auto-tab-${index}`}
      component="div"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      <Box>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),

  contentSpacing: {
    padding: '20px',
  },

  sectionSpacing: {
    marginBottom: '5rem',
    display: 'grid',
    gridAutoRows: 'auto',
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  paper: {
    margin: '.5rem',
  },
  dateSpacing: {
    marginTop: '.25rem',
  },
  imgFlexIntern: {
    maxWidth: '100%',
  },
  flexDiv: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  leftColImg: {
    paddingRight: '40px',
    paddingBottom: '20px',
  },
  imgFlexIntern: {
    maxWidth: '100%',
  },
  imgPhoto: {
    paddingBottom: '20px',
    [theme.breakpoints.down('sm')]: {
      maxHeight: '270px',
      maxWidth: '230px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '400px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '450px',
    },
  },
  tabHeader: {
    margin: theme.spacing(4, 0, 4, 0),
  },
  paragraphSpacing: {
    '& p': {
      margin: theme.spacing(2, 0, 2, 0),
    },
  },
  subHeader: {
    margin: theme.spacing(0, 0, 1, 0),
  },

  flexDivPr: {
    display: 'flex',
    flexDirection: 'column',
    border: 'solid #cccccc 1px',
    borderRadius: '5px',
    margin: theme.spacing(0, 3, 3, 0),

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  leftColImgPr: {
    [theme.breakpoints.down('sm')]: {
      maxHeight: '300px',
    },
  },
  buttonContainer: {
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
  },
  noUnderLine: { textDecoration: 'none' },
}))

//   ################### BEGINING CAROUSEL VARIABLES AND FUNCTIONS ##################
const PeoplePage = (props) => {
  const { data, location } = props
  const theme = useTheme()
  const css = useStyles(theme)
  const [execPeople, setexecPeople] = useState([])
  const [projPeople, setprojPeople] = useState([])
  const allPeople = data.allStrapiLeader.nodes
  const allImages = data.allStrapiImage.nodes

  const allArticles = filterArticles(data.allStrapiNew.nodes).map((node) => {
    if (node.image1) {
      node.image1 = findImage(node.image1.File, allImages)
    } else if (node.Header) {
      node.image1 = findImage(node.Header.File, allImages)
    } else {
      node.image1 = { file: null }
    }
    return node
  })

  const [visible, setVisible] = React.useState(8)
  const visibleBatches = allArticles.slice(0, visible)

  function chunkArray(myArray, chunk_size) {
    const results = []
    const local = myArray.map((item) => item)
    while (local.length) {
      results.push(local.splice(0, chunk_size))
    }
    return results
  }

  function showMore(event) {
    setVisible(visible + 8)
  }

  function determineSeeMoreVisibility() {
    const numVisible = visibleBatches.length
    const total = allArticles.length
    if (numVisible === total) {
      return false
    }
    return true
  }

  const cssSettings = {
    crop: false,
    image: {
      height: '140px',
      [theme.breakpoints.down('sm')]: {
        height: '140px',
        width: '120px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '280px',
        height: '300px',
      },
    },
    container: {
      height: '140px',
      width: '120px',
      borderRadius: '5px',
      [theme.breakpoints.down('sm')]: {
        height: '140px',
        width: '120px',
      },
      [theme.breakpoints.down('xs')]: {
        width: '280px',
        height: '300px',
      },
    },
  }

  const loadPeople = (arr) => {
    arr.map((p) => {
      if (p.ExecutiveLeader) {
        const arr = [...execPeople]
        arr.push(p)
        execPeople.push(p)
      } else {
        const arr = [...projPeople]
        arr.push(p)
        projPeople.push(p)
      }
    })
  }

  useEffect(() => {
    loadPeople(allPeople)
  }, [])

  const tabNames = ['leadership', 'org chart', 'profiles']

  const [activeTab, setActiveTab] = useState(() => determineInitialTab(props.location.href, tabNames))

  function handleTabChange(evt, newTabIndex) {
    setActiveTab(newTabIndex)
  }
  const paragraphBaseStyles = [css.aboutBodyText, css.pt22, css.openSans, css.opacity90, css.paragraphSpacing].join(' ')

  if (typeof window !== 'undefined') {
    window.addEventListener('popstate', (data) => {
      const { state } = data
      if (state && state.tabIndex >= 0) {
        setActiveTab(data.state.tabIndex)
      } else {
        setActiveTab(0)
      }
    })
  }

  const url = props.location.href || ''
  const queryParams = getQueryStringParams(url)
  const [tabParam, setTabParam] = useState('')

  useEffect(() => {
    determineActiveTab()
  }, [tabParam])

  function determineActiveTab() {
    let specifiedTabIdx = -1
    let tabParamValue = ''
    if (queryParams.tab) {
      tabParamValue = decodeURIComponent(queryParams.tab)
      specifiedTabIdx = tabNames.findIndex((tabName) => tabName.toLowerCase() === decodeURIComponent(queryParams.tab))
    }
    if (specifiedTabIdx < 0) {
      specifiedTabIdx = 0 // default
      tabParamValue = tabNames[specifiedTabIdx].toLowerCase()
      setTabParam(tabParamValue)
    }
    setActiveTab(specifiedTabIdx)
  }

  return (
    <Layout>
      <SEO description="People - ESC Public Site" title="People ESC" />
      <Helmet>
        <meta charSet="utf-8" />
      </Helmet>

      <ImageHeader image={data.headerImage} title="PEOPLE" />
      <div className={css.content}>
        <SubMenu changed={handleTabChange} selected={activeTab} />
      </div>

      <TabPanel className={paragraphBaseStyles} index={0} value={activeTab}>
        <div className={css.content}>
          <h2 className={[css.sansProSemiBold, css.pt40, css.uppercase, css.regular, css.tabHeader].join(' ')}>
            FROM THE ASSOCIATE DIRECTOR
          </h2>

          <div className={css.flexDiv}>
            <div className={css.leftColImg}>
              <figure>
                <img alt="Patrick A. Hill" className={css.imgPhoto} src={data.peopleImage1.childImageSharp.fluid.src} />
                <figcaption style={{ lineHeight: '17px', fontSize: '12px' }}>
                  Patrick A. Hill
                  <br />
                  Associate Director of Flight Projects
                  <br />
                  Exploration and Space Communications
                  <br />
                  NASA’s Goddard Space Flight Center
                  <br />
                </figcaption>
              </figure>
            </div>

            <div>
              NASA’s Exploration and Space Communications (ESC) projects division provides vital communications and
              navigation services through the Near Space Network – supporting everything from relatively small
              technology demonstrations to extremely bold scientific and human space flight endeavors.
              <br />
              <br />
              I have the privilege of leading ESC’s distinguished team of NASA personnel and contractor employees, all
              of whom are dedicated to realizing the agency’s vision for space exploration. Our executive leadership
              team, office chiefs, and project managers oversee a global network of NASA and commercial infrastructure
              that processes over 90% of NASA’s space data. With their teams, they guide technologies from formation to
              completion, introducing powerful innovations into the communications and navigation landscape.
              <br />
              <br />
              We are a collaborative organization, engaging beyond the walls of NASA with commercial companies,
              international partners, other government agencies, academia, and more. ESC works on behalf of NASA’s Space
              Communications and Navigation (SCaN) program, enacting their vision and building upon NASA’s 60+ years of
              space communications legacy.
              <br />
              <br /> I am proud to lead with such an exceptional team.
              <br />
              <br />
              {/* <img
                        src={data.peopleImage2.childImageSharp.fluid.src}
                        alt="Bob Menrad"
                     ></img> */}
            </div>
          </div>

          {/* ############################### EXECUTIVE LEADERS ############################# */}

          <div className={css.contentSpacing} id="execPeopleCarousel">
            <h2 className={[css.subHeader, css.regular].join(' ')}> EXECUTIVE LEADERSHIP</h2>

            <PeopleCarousel leaders={execPeople} />
          </div>

          {/* ############################### PROJECT LEADERS ############################# */}
          <div className={css.contentSpacing} id="projPeopleCarousel">
            <h2 className={[css.subHeader, css.regular].join(' ')}> PROJECT LEADERSHIP</h2>

            <PeopleCarousel leaders={projPeople} />
          </div>
        </div>
      </TabPanel>

      <TabPanel className={paragraphBaseStyles} index={1} value={activeTab}>
        <div className={css.content}>
          <div>
            {data.orgChartImage.childImageSharp && (
              <figure>
                <img
                  alt="Organizational chart "
                  className={css.imgFlexIntern}
                  src={data.orgChartImage.childImageSharp.fluid.src}
                />
              </figure>
            )}
          </div>
          <a
            className={[css.bold, css.noWordWrap, css.pt20].join(' ')}
            href="/static-files/450_ORG_CHART.pdf"
            target="_blank"
          >
            Download Org Chart (PDF)
          </a>
        </div>
      </TabPanel>

      <TabPanel className={paragraphBaseStyles} index={2} value={activeTab}>
        <div className={css.content}>
          <div className={css.contentSpacing} id="peopleProfiles">
            <h2 className={[css.sansProSemiBold, css.pt40, css.regular, css.tabHeader].join(' ')}>PROFILES</h2>
            {allArticles && (
              <div className={css.sectionSpacing}>
                {visibleBatches.map((pr, index) => (
                  <div className={css.flexDivPr} key={`ProfileMap-${index}`}>
                    <div className={css.leftColImgPr} key={`ProfImg-${index}`}>
                      {pr.image1 && (
                        <>
                          <ProfileImg css={cssSettings} image={pr.image1} />{' '}
                        </>
                      )}
                    </div>
                    <div className={[css.paper, css.opacity90].join(' ')} key={`ProfTxt-${index}`}>
                      <div>
                        <a className={[css.pt20, css.noUnderLine].join(' ')} href={`../news/${pr.Slug}`}>
                          {pr.Title}
                        </a>
                      </div>
                      <div className={[css.openSans, css.pt18, css.italic, css.dateSpacing].join(' ')}>
                        <AccessTime style={{ fontSize: '12px' }} />
                        &nbsp;
                        {pr.Publish}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {determineSeeMoreVisibility() && (
              <div className={css.buttonContainer}>
                <a
                  className={[css.sansPro, css.pt30, css.semiBold, css.uppercase, css.button].join(' ')}
                  onClick={showMore}
                >
                  See More
                </a>
              </div>
            )}
          </div>
        </div>
      </TabPanel>
    </Layout>
  )
}

export default WithLocation(PeoplePage)

export const pageQuery = graphql`
  query PeopleQuery {
    allStrapiLeader(sort: { fields: SortOrder }) {
      nodes {
        id
        Name
        Position
        Biography {
          data {
            Biography
          }
        }
        Education {
          data {
            Education
          }
        }
        ExecutiveLeader
        JoinedESC
        JoinedNasa
        Image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 370) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        project {
          Name
          Color
          Acronym
        }
      }
    }

    peopleImage1: file(relativePath: { eq: "people/hill-patrick.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    peopleImage2: file(relativePath: { eq: "people/bobmenradSignature.png" }) {
      childImageSharp {
        fluid(maxHeight: 300) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    orgChartImage: file(relativePath: { eq: "people/org_chart.png" }) {
      childImageSharp {
        fluid(maxHeight: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    missingImage: file(ext: { eq: ".jpg" }, name: { eq: "not-found" }) {
      id
      name
      publicURL
      childImageSharp {
        fluid(maxHeight: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    headerImage: file(relativePath: { eq: "headers/about.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    allStrapiNew(filter: { tags: { elemMatch: { Name: { eq: "Profile" } } } }, sort: { order: DESC, fields: Publish }) {
      nodes {
        Slug
        Title
        Publish(formatString: "MMMM DD, YYYY")
        Header {
          id
          Alt
          Caption
          File {
            id
          }
        }
        image1 {
          id
          Alt
          Caption
          File {
            id
          }
        }
      }
    }

    allStrapiImage {
      nodes {
        id
        Alt
        Caption
        File {
          id
          localFile {
            childImageSharp {
              fluid(maxHeight: 200) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`
