import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import Carousel from 'nuka-carousel'
import { useStaticQuery, graphql } from 'gatsby'
import Highlight from './highlight'
import PagingDots from './PagingDots'
import sharedStyles from '../styles/global'
import MissingImage from './missingImage'
import ImageOverlay from './imageOverlay'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  highlightsContainer: {
    width: '90%',
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr 1fr',
    gridTemplateAreas: 'a b c d e',
    gridGap: '10px',
    alignItems: 'center',
    paddingTop: '5px',
  },
  GridGap: {
    marginRight: '10px',
  },
  highlightSlider: {
    display: 'none',
    maxHeight: '500px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: '250px',
    },
  },
  highlightImages: {
    marginTop: '.5rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  slider: {
    color: 'white',
    paddingLeft: '15px',
    paddingRight: '15px',
    '& a': {
      color: 'white',
    },
  },
  whiteLinkColor: { color: '#ffffff' },
  tagSpacing: {
    paddingBottom: '0.8rem',
  },
  headerSpacing: {
    paddingBottom: '0rem',
    marginBottom: '0.3rem',
  },
  headerMobile: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  mainContainer: {
    position: 'relative',
    margin: '0 auto',
    height: '400px',
    maxWidth: '1200px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundPosition: 'bottom',
  },
  spectrum: {
    width: '100%',
    display: 'block',
  },
  readMoreCarouselSpacing: {
    paddingLeft: '1rem',
    marginBottom: '1.6rem',
  },
  fullSizeReadMore: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'block',
    },
  },
}))

const SpotLightText = (props) => {
  const { highlight, css } = props
  return (
    <>
      {highlight.Title && (
        <h1 className={[css.headerMobile, css.sansPro, css.headerSpacing].join(' ')}>
          {highlight.Title.trim() == '' ? (
            <div>
              <br />
              <br />
            </div>
          ) : (
            highlight.Title
          )}
        </h1>
      )}
      <a
        className={[css.pt22, css.bold, css.whiteLinkColor, css.uppercase, css.fullSizeReadMore].join(' ')}
        href={highlight.Link}
      >
        Read more
      </a>
    </>
  )
}

const SpotLight = (props) => {
  const theme = useTheme()
  const css = useStyles(theme)
  let highlights = props.data
  const gridTemplateAreaValues = ['a', 'b', 'c', 'd', 'e']
  const [activeHighlight, setActiveHighlight] = React.useState(0)
  const [isSlideShowActive, setSlideShowActive] = React.useState(true)
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const selectedHighlight = highlights[activeHighlight]
  selectedHighlight.selected = true

  let highlightCss = [css.GridGap]
  if (typeof window !== 'undefined') {
    highlightCss = []
  }

  function handleHighlight(event, value) {
    setActiveHighlight(value)
    stopSlideShow()
    highlights = highlights.map((highlight, index) => {
      if (index === parseInt(value)) {
        highlight.selected = true
      } else {
        highlight.selected = false
      }
      return highlight
    })
  }

  const data = useStaticQuery(graphql`
    query {
      spectrum: file(ext: { eq: ".png" }, name: { eq: "spectrum" }) {
        id
        name
        publicURL
        childImageSharp {
          fluid {
            src
          }
        }
      }
    }
  `)
  const t = setTimeout(nextSlide, 7000)

  function stopSlideShow() {
    setSlideShowActive(false)
    clearTimeout(t)
  }

  function startSlideShow() {
    setSlideShowActive(true)
  }

  function nextSlide() {
    // -- only automate slides on non-mobile screens
    if (!isMobile) {
      if (isSlideShowActive) {
        selectedHighlight.selected = false
        const nextIndex = (activeHighlight + 1) % highlights.length
        setActiveHighlight(nextIndex)
      } else {
        // -- restart the slide show after 30 seconds
        setTimeout(startSlideShow, 30000)
      }
    }
  }

  const selectedHighlightCss = {
    crop: true,
    image: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    container: {
      height: '400px',
    },
    overlay: {
      background: 'linear-gradient(to top,  rgba(12,29,82,0.9) 10%,transparent  )',
    },
    text: {
      top: '85%',
    },
  }
  if (selectedHighlight.Gradient === false) {
    selectedHighlightCss.overlay.background = ''
  }
  return (
    <div>
      <div className={css.highlightSlider}>
        <Carousel
          afterSlide={(slideIndex) => setActiveHighlight(slideIndex)}
          height="255px"
          heightMode="current"
          renderBottomCenterControls={() => {}}
          renderBottomLeftControls={(props) => (
            <div className={css.readMoreCarouselSpacing}>
              <a
                className={[css.pt22, css.bold, css.whiteLinkColor, css.uppercase].join(' ')}
                href={selectedHighlight.Link}
              >
                Read more
              </a>{' '}
            </div>
          )}
          renderBottomRightControls={(props) => <PagingDots {...props} />}
          renderCenterLeftControls={() => (
            <div className={css.slider}>
              <SpotLightText css={css} highlight={selectedHighlight} />
            </div>
          )}
          renderCenterRightControls={({ nextSlide }) => ''}
          slideIndex={parseInt(activeHighlight)}
          swiping
          transitionMode="scroll"
          wrapAround
        >
          {highlights.map((highlight, index) => {
            if (highlight.Image.localFile) {
              const imageCss = {
                container: { height: '250px' },
                image: { height: '250px' },
              }
              if (highlight.Gradient === false) {
                imageCss.overlay = { background: '' }
              }
              return (
                <ImageOverlay
                  alt={highlight.Name}
                  css={imageCss}
                  image={highlight.Image.localFile}
                  key={`SliderImage_${index}`}
                />
              )
            }
            return <MissingImage />
          })}
        </Carousel>
      </div>
      <div className={[css.mainContainer, css.highlightImages].join(' ')}>
        <ImageOverlay alt={selectedHighlight.Alt} css={selectedHighlightCss} image={selectedHighlight.Image.localFile}>
          <div>
            <div>
              <SpotLightText css={css} highlight={selectedHighlight} />
            </div>
          </div>
        </ImageOverlay>
        <div>
          <img className={css.spectrum} src={data.spectrum.publicURL} />
        </div>
      </div>
      <div className={[css.highlightsContainer, css.highlightImages].join(' ')}>
        {highlights.map((highlight, index) => {
          const gridColumn = index + 1
          const gridAreaValue = `'${gridTemplateAreaValues[index]}'`
          return (
            <div
              className={highlightCss.join(' ')}
              key={`highlightImage_${index}`}
              style={{
                gridArea: gridAreaValue,
              }}
              onClick={(e) => handleHighlight(e, `${index}`)}
            >
              <Highlight data={highlight} />
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default SpotLight
