import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Carousel from 'nuka-carousel'
import YouTube from 'react-youtube'
import { AccessTime, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons'
import _ from 'lodash'
import Layout from '../components/layout'
import sharedStyles from '../styles/global'
import MediaTabs from '../components/mediaTabs'
import ImageOverlay from '../components/imageOverlay'
import Modal from '../components/modal'
import ImageHeader from '../components/imageHeader'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  carouselContainer: {
    display: 'grid',
    gridGap: '10px',
    paddingLeft: '25px',
    marginRight: '35px',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    [theme.breakpoints.down('md')]: {
      gridTemplateColumns: '1fr 1fr 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr 1fr',
    },
  },
  projectVideos: {
    marginTop: '30px',
  },
  tabHeader: {
    margin: theme.spacing(6, 0, 4, 0),
  },
  pointer: {
    cursor: 'pointer',
  },
  carousel: {
    cursor: 'default',
    '& ul.slider-list': {
      cursor: 'default !important',
    },
  },
  headingSpacing: {
    marginBottom: '0.4rem',
  },
  tagSpacing: {
    margin: theme.spacing(4, 0, 0, 0),
    paddingBottom: '0.8rem',
  },
  titleSpacing: {
    paddingTop: '0.6rem',
    paddingBottom: '0.2rem',
    lineHeight: 1.2,
  },
  contentSpacing: {
    paddingTop: '1.5rem',
    paddingBottom: '1.5rem',
  },
}))

// -- helps calculate the window size
let defaultWidth
let defaultHeight
if (typeof window !== 'undefined') {
  defaultHeight = window.innerHeight
  defaultWidth = window.innerWidth
}
const useWindowSize = () => {
  const [dimensions, setDimensions] = useState({
    windowHeight: defaultHeight,
    windowWidth: defaultWidth,
  })
  useEffect(() => {
    const handler = () =>
      setDimensions({
        windowHeight: window.innerHeight,
        windowWidth: window.innerWidth,
      })
    window.addEventListener('resize', handler)
    return () => window.removeEventListener('resize', handler)
  }, [])
  return dimensions
}

const getNumPictures = (theme) => {
  let numPictures = 4
  const viewWidth = useWindowSize(1200).windowWidth
  if (viewWidth < theme.breakpoints.values.lg) {
    numPictures = 3
  }
  if (viewWidth < theme.breakpoints.values.sm) {
    numPictures = 2
  }
  return numPictures
}

const VideoPage = ({ data }) => {
  const theme = useTheme()
  const css = useStyles(theme)
  const numPictures = getNumPictures(theme)
  const videos = data.videos.nodes

  const [open, setOpen] = React.useState(false)
  const handleClose = () => {
    setOpen(false)
  }

  const handleVideoClick = (video, event) => {
    setSelectedVideo(video)
    setOpen(true)
  }

  // -- bail out if no videos are found
  if (videos.length === 0) return <div>No videos found</div>

  //  Find the video marked as isFeatured
  const featuredVideo = videos[0]
  const projectVideos = videos.slice(1)
  const [selectedVideo, setSelectedVideo] = React.useState(featuredVideo)

  return (
    <Layout>
      <ImageHeader image={data.headerImage} title="News & Multimedia" />

      <div className={css.content}>
        <MediaTabs selected={1} />
        <div className={[css.sansProSemiBold, css.pt40, css.regular, css.uppercase, css.tabHeader].join(' ')}>
          Featured Video
        </div>
        <YouTube
          opts={{ width: '100%', height: 400 }}
          videoId={
            //  Get the video ID by its query string parameter in the URL
            featuredVideo.YoutubeUrl.split('v=')[1]
          }
        />
        <p className={[css.pt20, css.sansPro, css.bold, css.uppercase, css.opacity60, css.tagSpacing].join(' ')}>
          {featuredVideo.tags.map((tag) => tag.Name).join(' ')}
        </p>
        <h1 className={[css.sansPro, css.pt70, css.bold, css.headingSpacing].join(' ')}>{featuredVideo.Title}</h1>
        <p className={[css.pt35, css.sansPro].join(' ')}>{featuredVideo.SubHeader}</p>
        <p className={[css.openSans, css.pt20, css.regular, css.contentSpacing].join(' ')}>
          {featuredVideo.Description}
        </p>
        <div className={css.projectVideos}>
          <h3 className={[css.sansProSemiBold, css.pt40, css.regular, css.uppercase, css.tabHeader].join(' ')}>
            Project Videos
          </h3>
          <Carousel
            className={css.carousel}
            renderBottomCenterControls={(props) => ''}
            renderCenterLeftControls={({ previousSlide }) => (
              <KeyboardArrowLeft className={css.pointer} onClick={previousSlide} />
            )}
            renderCenterRightControls={({ nextSlide }) => (
              <KeyboardArrowRight className={css.pointer} onClick={nextSlide} />
            )}
          >
            {_.chunk(projectVideos, numPictures).map((chunk, i) => (
              <div key={`chunk_${i}`}>
                <div className={css.carouselContainer}>
                  {chunk.map((video, index) => (
                    <div key={`image_${index}`}>
                      <a className={css.pointer} onClick={() => handleVideoClick(video)}>
                        <ImageOverlay
                          alt={video.Title}
                          css={{
                            container: {
                              '&:hover': {
                                '& > div': {
                                  background: 'rgba(29,55,135,0.7)',
                                },
                                '& > span': {
                                  display: 'block',
                                },
                              },
                            },
                            image: { height: '120px' },
                            overlay: {
                              background: '',
                            },
                            text: {
                              top: '15%',
                              lineHeight: 1.25,
                              display: 'none',
                              paddingLeft: '15px',
                            },
                          }}
                          image={video.Thumbnail.localFile}
                        >
                          <div
                            className={[
                              css.sansPro,
                              css.pt20,
                              css.bold,
                              css.uppercase,
                              css.opacity80,
                              css.uppercase,
                            ].join(' ')}
                          >
                            {video.tags.map((tag) => tag.Name).join(', ')}
                          </div>
                        </ImageOverlay>
                      </a>
                      <div>
                        <div className={[css.sansProBold, css.pt18, css.titleSpacing].join(' ')}>
                          <a className={css.pointer} onClick={() => handleVideoClick(video)}>
                            {video.Title}
                          </a>
                        </div>
                        <div className={[css.pt16, css.openSans, css.italic].join(' ')}>
                          <AccessTime style={{ fontSize: '12px' }} />
                          &nbsp;
                          {video.Publish}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </Carousel>
        </div>
        <Modal handleClose={handleClose} isOpen={open} tags={selectedVideo.tags} title={selectedVideo.Title}>
          <YouTube
            opts={{ width: 711, height: 400 }}
            videoId={
              //  Get the video ID by its query string parameter in the URL
              selectedVideo.YoutubeUrl.split('v=')[1]
            }
          />
        </Modal>
      </div>
    </Layout>
  )
}

export default VideoPage

export const pageQuery = graphql`
  query VideoQuery {
    videos: allStrapiVideo(sort: { order: DESC, fields: Publish }) {
      nodes {
        Title
        tags {
          Name
          id
        }
        Description
        id
        Publish(formatString: "MMMM D, YYYY")
        YoutubeUrl
        Thumbnail {
          localFile {
            childImageSharp {
              fluid(maxHeight: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
    headerImage: file(relativePath: { eq: "headers/newsandmultimedia.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
