import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { Box, useMediaQuery } from '@material-ui/core'
import sharedStyles from '../styles/global'
import QuickFactPanel from './quickFactPanel'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  gridContainer: {
    width: '100%',
    display: 'grid',
    gridTemplateRows: '300px',
    gridAutoRows: '300px',
    gridTemplateColumns: 'repeat(4, minmax(220px, 1fr))',
    [theme.breakpoints.down('950')]: {
      gridTemplateColumns: 'repeat(3, minmax(210px, 1fr))',
    },
    [theme.breakpoints.down('700')]: {
      gridTemplateColumns: 'repeat(2, minmax(220px, 1fr))',
    },
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '250px',
    },
    gridGap: '0.7rem',
    justifyItems: 'center',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

const QuickFacts = (props) => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const { facts, color } = props

  let columns = 4
  let rows = 4
  if (useMediaQuery(theme.breakpoints.down('950'))) {
    columns = 3
    rows = 5
  }
  if (useMediaQuery(theme.breakpoints.down('700'))) {
    columns = 2
    rows = 8
  }
  if (useMediaQuery(theme.breakpoints.down('xs'))) {
    columns = 1
    rows = 15
  }

  return (
    <Box className={[classes.gridContainer, classes.fadedBlueBg].join(' ')} px={10} py={6}>
      {facts.map((fact, idx) => (
        <QuickFactPanel color={color} fact={fact} key={idx} />
      ))}
    </Box>
  )
}

export default QuickFacts
