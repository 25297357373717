import React from 'react'
import { graphql, Link } from 'gatsby'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Layout from '../components/layout'
import SmallerNewsPanel from '../components/smallerNewsPanel'
import LargerNewsPanel from '../components/largerNewsPanel'
import ImageOverlay from '../components/imageOverlay'
import sharedStyles from '../styles/global'
import MediaTabs from '../components/mediaTabs'
import ImageHeader from '../components/imageHeader'
import { filterArticles, findImage } from '../services/articleService'

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  secondaryLayout: {
    display: 'grid',
    gridTemplateColumns: '7fr 3fr',
    gridGap: '15px',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      marginLeft: '20px',
      marginRight: '20px',
    },
  },
  buttonContainer: {
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
  },
  button: {
    margin: 'auto',
  },
  headerSpacing: {
    margin: theme.spacing(4, 0, 4, 0),
  },
  featureHeaderSpacing: {
    paddingBottom: '0.3rem',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.3rem',
    },
  },
  tagSpacing: {
    paddingBottom: '0.8rem',
  },
  publishSpacing: {
    paddingTop: '1.0rem',
  },
}))

const NewsMediaPage = ({ data }) => {
  const theme = useTheme()
  const css = useStyles(theme)

  const allImages = data.allStrapiImage.nodes
  const allArticles = filterArticles(data.allStrapiNew.nodes).map((node) => {
    if (node.Header) {
      node.Header = findImage(node.Header.File, allImages)
    } else {
      // handle the case where there is no Header image
      node.Header = { File: null }
    }
    return node
  })
  const featured = allArticles.shift()
  const allBatches = chunkArray(allArticles, 5)
  const [visible, setVisible] = React.useState(1)
  const visibleBatches = allBatches.slice(0, visible)
  const cssSettings = {
    crop: false,
    container: {
      maxHeight: '400px',
      '&:hover': {
        '& > div': {
          background: 'rgba(29,55,135,0.9)',
        },
      },
    },
    image: {},
  }

  function chunkArray(myArray, chunk_size) {
    const results = []
    const local = myArray.map((item) => item)
    while (local.length) {
      results.push(local.splice(0, chunk_size))
    }
    return results
  }

  function showMore(event) {
    setVisible(visible + 3)
  }

  const headerStyles = [css.sansProSemiBold, css.pt40, css.regular, css.headerSpacing, css.uppercase].join(' ')
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'))
  return (
    <Layout>
      <ImageHeader image={data.headerImage} title="News & Multimedia" />
      <div className={css.content}>
        <MediaTabs selected={0} />

        <div className={[headerStyles].join(' ')}>Featured Article</div>
        <Link to={`../news/${featured.Slug}`}>
          <ImageOverlay alt={featured.Alt} css={cssSettings} image={featured.Header.File.localFile} showCaption={false}>
            <div className={[css.sansPro, css.pt20, css.bold, css.uppercase, css.opacity70, css.tagSpacing].join(' ')}>
              {featured.tags.length > 0 ? featured.tags[0].Name : ' '}
            </div>
            <h2 className={[css.sansPro, css.pt70, css.featureHeaderSpacing].join(' ')}>{featured.Title}</h2>
            <p className={[css.pt20, css.openSans, css.semiBold].join(' ')} style={{ opacity: 0.91 }}>
              {featured.Blurb}
            </p>
            <p className={[css.sansPro, css.pt18, css.regular, css.opacity60, css.publishSpacing].join(' ')}>
              {featured.Publish}
            </p>
          </ImageOverlay>
        </Link>
        <br />
        <h3 className={headerStyles}>Latest Articles</h3>
        {visibleBatches.map((batch, index) => {
          const largerPanels = batch.slice(0, 2)
          const smallerPanels = batch.slice(2, 5)

          return (
            <div className={[css.secondaryLayout].join(' ')} key={`NewsBatch_${index}`}>
              <div>
                {largerPanels.map((panel, index) => (
                  <div key={`largerNewsPanel_${index}`}>
                    {isSmallScreen ? <SmallerNewsPanel article={panel} /> : <LargerNewsPanel article={panel} />}
                    <br />
                  </div>
                ))}
              </div>
              <div>
                {smallerPanels.map((panel, index) => (
                  <div key={`smallerNewsPanel_${index}`}>
                    <SmallerNewsPanel article={panel} />
                    <br />
                  </div>
                ))}
              </div>
            </div>
          )
        })}
        <div className={css.buttonContainer}>
          <a className={[css.sansPro, css.pt30, css.semiBold, css.uppercase, css.button].join(' ')} onClick={showMore}>
            See More
          </a>
        </div>
      </div>
    </Layout>
  )
}

export default NewsMediaPage

export const pageQuery = graphql`
  query NewsMediaQuery {
    allStrapiNew(sort: { order: DESC, fields: Publish }) {
      nodes {
        id
        Title
        Slug
        Publish(formatString: "MMMM D, YYYY")
        IsDataExample
        tags {
          Name
        }
        Header {
          id
          Alt
          Caption
          File {
            id
          }
        }
        image1 {
          id
          Alt
          Caption
          File {
            id
          }
        }
      }
    }
    allStrapiImage {
      nodes {
        id
        Alt
        Caption
        File {
          id
          localFile {
            childImageSharp {
              fluid(maxHeight: 960, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }

    headerImage: file(ext: { eq: ".jpg" }, name: { eq: "newsandmultimedia" }) {
      childImageSharp {
        fluid(maxHeight: 960, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    missingImage: file(ext: { eq: ".jpg" }, name: { eq: "not-found" }) {
      id
      name
      publicURL
      childImageSharp {
        fluid(maxHeight: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
