import { Link, useStaticQuery, graphql, navigate } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Img from 'gatsby-image'
import { makeStyles, useTheme, useScrollTrigger, Button, AppBar, Toolbar } from '@material-ui/core'

import SearchIcon from '@material-ui/icons/Search'
import MenuIcon from '@material-ui/icons/Menu'
import SearchBox from './searchBox'
import sharedStyles from '../styles/global'

function ElevationScroll(props) {
  const { children, window } = props
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  })
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
}

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  appBar: {
    backgroundColor: 'white',
  },
  toolBar: {
    // make width match global .content class
    width: '1000px',
    [theme.breakpoints.down(1000)]: {
      width: '100%',
    },
    margin: 'auto',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    alignItems: 'center',
  },
  meatballLink: {
    justifySelf: 'start',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '-5px',
      left: '5px',
      padding: '5px',
    },
  },
  escLogoLink: {
    justifySelf: 'start',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '5px',
      left: '80px',
      padding: '10px',
    },
  },
  nasaLogo: {
    height: '70px',
    width: '70px',
  },
  spectrum: {
    width: '100%',
    padding: '0px',
    margin: '0px',
    [theme.breakpoints.down('sm')]: {
      margin: '5px',
    },
  },

  navigation: {
    backgroundColor: '#0c1d52',
    color: '#fff',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
      flexDirection: 'column',
    },
  },
  navButton: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('640')]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  searchBoxDesktop: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    '& input': {
      marginRight: theme.spacing(1),
    },
  },
  mobileControls: {
    display: 'flex',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  searchBoxMobile: {
    display: 'flex',
    position: 'absolute',
    top: '35px',
    right: '50px',
    fontSize: '17pt',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
    '& input': {
      marginRight: theme.spacing(1),
    },
  },
  searchIcon: {
    verticalAlign: 'top',
    position: 'relative',
    color: '#0c1d52',
    fontSize: '20pt',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '2px',
      right: '-30px',
      fontSize: '14pt',
    },
  },
  searchBox: {
    verticalAlign: 'top',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '42px',
      right: '50px',
      fontSize: '17pt',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  menuIcon: {
    color: '#0c1d52',
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: '5px',
      right: '0px',
      fontSize: '17pt',
    },
  },
  rainbowBar: {
    height: '8px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
}))

const Header = () => {
  const hideshow = function hideshow(id) {
    const obj = document.getElementById(id)
    if (obj.style.display === 'flex') obj.style.display = 'none'
    else obj.style.display = 'flex'
  }
  const goto = function (event, name) {
    navigate(`/${name}`)
  }
  const menu = [
    { name: 'Home', slug: '' },
    { name: 'About', slug: 'about' },
    { name: 'People', slug: 'people' },
    { name: 'Projects', slug: 'projects' },
    { name: 'Partnerships', slug: 'partnerships' },
    { name: 'Obtain Services', slug: 'services' },
    { name: 'News & Multimedia', slug: 'news-media' },
    { name: 'Participate', slug: 'participate' },
  ]

  const theme = useTheme()
  const classes = useStyles(theme)
  const data = useStaticQuery(graphql`
    query {
      nasaLogo: file(relativePath: { eq: "nasa-logo.svg" }) {
        name
        publicURL
      }
      spectrum: file(relativePath: { eq: "spectrum.png" }) {
        name
        publicURL
      }
      esc: file(relativePath: { eq: "ESC_Logo_Horizontal.png" }) {
        childImageSharp {
          fixed(height: 40) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)
  const openSearch = function () {
    const queryDesktop = document.querySelector('#searchBoxDesktop input')
    const queryMobile = document.querySelector('#searchBoxMobile input')
    console.log('Desktop query:', queryDesktop?.value)
    console.log('Mobile query:', queryMobile?.value)
    const query = queryDesktop?.value || queryMobile?.value
    if (query) {
      const queryString = `query=${encodeURIComponent(query)}`
      navigate(`/search?${queryString}`)
    }
  }

  return (
    <div>
      <ElevationScroll>
        <AppBar className={classes.appBar}>
          <Toolbar className={classes.toolBar}>
            <div className={classes.meatballLink}>
              <a href="https://www.nasa.gov/" rel="noreferrer" target="_blank">
                <img alt="NASA Logo" className={classes.nasaLogo} src={data.nasaLogo.publicURL} />
              </a>
            </div>
            <div className={classes.escLogoLink}>
              <Link to="/">
                <Img fixed={data.esc.childImageSharp.fixed} />
              </Link>
            </div>

            <div className={classes.toolbarSearchGroup}>
              <div className={classes.searchBoxDesktop} id="searchBoxDesktop">
                <SearchBox />
                <SearchIcon className={[classes.searchIcon, classes.pointer].join(' ')} onClick={(e) => openSearch()} />
              </div>

              <div className={classes.searchBoxMobile} id="searchBoxMobile">
                <SearchBox />
                <SearchIcon className={[classes.searchIcon, classes.pointer].join(' ')} onClick={(e) => openSearch()} />
              </div>

              <div className={classes.mobileControls}>
                <Button className={classes.menuIcon} color="inherit" onClick={(e) => hideshow('menu')}>
                  <MenuIcon />
                </Button>
              </div>
            </div>
          </Toolbar>

          <img alt="Spectrum" className={classes.spectrum} src={data.spectrum.publicURL} />

          <nav className={classes.navigation} id="menu">
            {menu.map((option) => (
              <Button
                className={[classes.navButton, classes.sansProBold].join(' ')}
                color="inherit"
                key={option.slug}
                onClick={(e) => goto(e, `${option.slug}`)}
              >
                {option.name}
              </Button>
            ))}
          </nav>
        </AppBar>
      </ElevationScroll>
    </div>
  )
}

Header.propTypes = {
  // eslint-disable-next-line react/no-unused-prop-types
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header
