import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { useMediaQuery } from '@material-ui/core'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Markdown from 'react-markdown/with-html'
import SpotLight from '../components/spotLight'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Modal from '../components/modal'
import ContactUs from '../components/contactUs'
import sharedStyles from '../styles/global'
import ImageOverlay from '../components/imageOverlay'

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <Typography
      aria-labelledby={`scrollable-auto-tab-${index}`}
      component="div"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      role="tabpanel"
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  )
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  }
}

const useStyles = makeStyles((theme) => ({
  ...sharedStyles(theme),
  feature: {
    display: 'grid',
    '& h3': {
      textAlign: 'center',
      paddingTop: '20px',
      paddingBottom: '25px',
    },
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
      textJustify: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr 1fr',
      '& h3': {
        textAlign: 'left',
      },
    },
    marginTop: '25px',
    marginBottom: '25px',
  },
  featureImage: {
    alignSelf: 'center',
    justifySelf: 'center',
  },
  mainHeader: {
    margin: theme.spacing(0, 0, 2, 0),
  },
  featureSection: {
    margin: theme.spacing(7, 0, 7, 0),
    // display: 'flex',
    flexDirection: 'column',
  },
  featureTextContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& h2': {
      margin: theme.spacing(0, 0, 2, 0),
    },
    [theme.breakpoints.down('sm')]: {
      '& h2': {
        margin: theme.spacing(3, 0, 2, 0),
        textAlign: 'center',
      },
    },
  },
  expertiseSection: {
    padding: theme.spacing(5, 0, 0, 0),
    '& h1': {
      margin: theme.spacing(0, 0, 1, 0),
    },
  },
  expertiseTabContent: {
    margin: theme.spacing(3, 0),
    display: 'grid',
    gridTemplateRows: '1fr max-content',
    gridGap: theme.spacing(2),
    // for repeating alternating rows
    // when in mobile 1 column layout
    gridTemplateAutoRows: '1fr max-content',
    [theme.breakpoints.down('xs')]: {
      gridGap: theme.spacing(3),
    },
  },
  expertise2ColGrid: {
    gridTemplateColumns: '1fr 1fr',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  expertise3ColGrid: {
    gridTemplateColumns: '1fr 1fr 1fr',
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '1fr',
    },
  },
  expertiseIconDiv: {
    height: '190px',
    alignSelf: 'center',
    justifySelf: 'center',
    textAlign: 'center',
    margin: theme.spacing(0, 0, 1, 0),
  },
  expertiseIcon: {
    alignSelf: 'center',
    justifySelf: 'center',
    height: '190px',
    margin: theme.spacing(0, 0, 1, 0),
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  quoteSection: {
    padding: theme.spacing(8, 0, 12, 0),
    textAlign: 'center',
    fontWeight: 'bold',
  },
  quoteIcon: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(5),
    height: theme.spacing(8),
  },
  subTitle: {
    textAlign: 'right',
    fontWeight: 'normal',
    textJustify: 'auto',
  },
  contactSection: {
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    color: 'white',
  },
  contactUsSpacing: {
    padding: theme.spacing(8, 0, 8, 0),
    display: 'flex',
    flexDirection: 'column',
    justifyItems: 'space-between',
    '& *': {
      margin: theme.spacing(2, 0),
    },
  },
  contactButton: {
    textAlign: 'center',
    margin: 'auto',
    '& button': {
      padding: theme.spacing(1.3, 6),
      border: '1px solid white',
      borderRadius: '8px',
      color: 'white',
      backgroundColor: 'transparent',
      cursor: 'pointer',
    },
  },
}))

const IndexPage = ({ data }) => {
  const { contactImage } = data
  const pageContent = data.allHomePageJson.nodes[0]
  const theme = useTheme()
  const css = useStyles(theme)
  const [activeTab, setActiveTab] = React.useState(0)
  const icons = data.allFile.nodes.reduce((map, icon) => {
    if (icon.extension === 'svg') {
      map[icon.name] = icon.publicURL
    }
    return map
  }, {})
  const images = data.allFile.nodes.reduce((map, image) => {
    if (image.extension != 'svg') {
      map[image.name] = image
    }
    return map
  }, {})

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const expertiseTabStyles = pageContent.expertise.tabs.map((tab) => {
    const tabStyles = [css.expertiseTabContent]
    if (tab.columns.length === 2) {
      tabStyles.push(css.expertise2ColGrid)
    }
    if (tab.columns.length === 3) {
      tabStyles.push(css.expertise3ColGrid)
    }
    return tabStyles
  })

  const highlights = data.allStrapiHighlight.nodes
  const visibleHighlights = highlights.slice(0, 5)

  const layoutExpertiseGridItems = (columns, images, css, isSmallScreen, tabIndex) => {
    const elementsInOrder = []

    const imageCss = {
      overlay: { background: '' },
      image: { height: '200px', marginRight: theme.spacing(2) },
    }

    columns.forEach((column, colIdx) => {
      elementsInOrder.push(
        <div
          className={css.expertiseIconDiv}
          key={`image-${tabIndex}-${colIdx}`}
          style={{
            width: '100%',
          }}
        >
          <ImageOverlay css={imageCss} image={images[column.paragraphImage]} />
        </div>
      )
      // if small screen push text immediately
      // after image to alternate down one column
      if (isSmallScreen) {
        elementsInOrder.push(
          <div key={`text-${tabIndex}-${colIdx}`}>
            <p className={[css.pt20].join(' ')}>
              <Markdown escapeHtml={false} key="columnText" source={column.text} />
            </p>
          </div>
        )
      }
    })
    // if not small screen push all text elements
    // after all image elements to create two rows
    if (!isSmallScreen) {
      columns.forEach((column, colIdx) => {
        elementsInOrder.push(
          <div key={`text-${tabIndex}-${colIdx}`}>
            <p className={[css.pt20].join(' ')}>
              <Markdown escapeHtml={false} key="columnText" source={column.text} />
            </p>
          </div>
        )
      })
    }
    return elementsInOrder
  }

  function handleChange(event, newValue) {
    setActiveTab(newValue)
  }

  // this is the code for the popup
  const [open, setOpen] = React.useState(false)
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }
  // end of code for popup

  return (
    <Layout>
      <SEO title="Home ESC" />
      <Helmet />

      <SpotLight data={visibleHighlights} />
      <br />
      <section>
        <div className={css.content}>
          <h1 className={[css.pt65, css.sansProBold, css.uppercase, css.center, css.mainHeader].join(' ')}>
            {pageContent.main.header}
          </h1>
          <p className={[css.pt22].join(' ')}>
            <Markdown escapeHtml={false} key="mainMrk" source={pageContent.main.body} />
          </p>

          <div className={css.featureSection}>
            {pageContent.main.features.map((feature, featureIndex) => {
              const gridRow = featureIndex + 1
              return (
                <div className={css.feature} key={`feature_${featureIndex}`}>
                  <div
                    className={css.featureImage}
                    style={{
                      width: '90%',
                    }}
                  >
                    <ImageOverlay
                      css={{
                        overlay: { background: '' },
                        image: { height: '200px' },
                      }}
                      image={images[feature.paragraphImage]}
                    />
                  </div>

                  <div
                    className={css.featureTextContainer}
                    style={{
                      width: '100%',
                      height: '100%',
                      backgroundImage: `url(${icons[feature.backgroundIcon]})`,
                      backgroundPosition: 'center',
                      backgroundRepeat: 'no-repeat',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: '100%',
                        background: 'rgba(255,255,255,.9)',
                      }}
                    >
                      <h2 className={[css.pt40, css.sansProSemiBold, css.uppercase].join(' ')}>{feature.header}</h2>
                      <p className={[css.pt20].join(' ')}>
                        {/* {feature.body} */}
                        <Markdown escapeHtml={false} key={`featureMrk_${featureIndex}`} source={feature.body} />
                      </p>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </section>
      <section className={[css.fadedBlueBg, css.expertiseSection].join(' ')}>
        <div className={css.content}>
          <h1 className={[css.pt40, css.sansProBold, css.uppercase].join(' ')}>{pageContent.expertise.header}</h1>
          <Tabs
            aria-label="scrollable auto tabs example"
            indicatorColor="primary"
            scrollButtons="auto"
            textColor="primary"
            value={activeTab}
            variant="scrollable"
            onChange={handleChange}
          >
            {pageContent.expertise.tabs.map((tab, expertiseHeaderIndex) => {
              const tabStyles = [css.pt22, css.sansProSemiBold]
              if (expertiseHeaderIndex !== activeTab) {
                tabStyles.push(css.nonActiveTab)
              }
              const tabcss = tabStyles.join(' ')
              return (
                <Tab
                  className={tabcss}
                  label={tab.header}
                  {...a11yProps(expertiseHeaderIndex)}
                  key={`ExpertiseTabHeader_${expertiseHeaderIndex}`}
                />
              )
            })}
          </Tabs>

          {pageContent.expertise.tabs.map((tab, expertiseTabIndex) => (
            <TabPanel index={expertiseTabIndex} key={`ExpertiseTab_${expertiseTabIndex}`} value={activeTab}>
              <p className={[css.pt22].join(' ')}>
                <Markdown escapeHtml={false} source={tab.text} />
              </p>
              <div className={expertiseTabStyles[expertiseTabIndex].join(' ')}>
                {layoutExpertiseGridItems(tab.columns, images, css, isSmallScreen, expertiseTabIndex)}
              </div>
            </TabPanel>
          ))}
        </div>
      </section>
      <section className={css.quoteSection}>
        <div className={css.content}>
          <img alt="quote icon" className={css.quoteIcon} src={icons.testimonial} />
          <span className={[css.pt40, css.sansPro, css.uppercase].join(' ')}>
            {/* {pageContent.pulledQuote.text} */}
            <Markdown escapeHtml={false} source={pageContent.pulledQuote.text} />
          </span>
        </div>
      </section>

      <section
        className={[css.contactSection].join(' ')}
        style={{
          backgroundImage: `url(${contactImage.childImageSharp.fluid.src})`,
        }}
      >
        <div className={[css.imageGradient].join(' ')}>
          <div className={[css.content, css.contactUsSpacing].join(' ')}>
            <h3 className={[css.pt40, css.sansProBold, css.center, css.uppercase].join(' ')}>Contact Us</h3>
            <p className={[css.pt22, css.center].join(' ')}>{pageContent.contactUs.text}</p>
            <div className={css.contactButton}>
              <button className={[css.sansPro, css.pt22].join(' ')} onClick={handleClickOpen}>
                {pageContent.contactUs.buttonText}
              </button>
            </div>
          </div>
        </div>
      </section>
      <Modal handleClose={handleClose} isOpen={open} title="Contact Us">
        <ContactUs />
      </Modal>
    </Layout>
  )
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    contactImage: file(relativePath: { eq: "Image.jpg" }) {
      childImageSharp {
        fluid(maxHeight: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    allStrapiHighlight(sort: { fields: Order }) {
      nodes {
        Alt
        Blurb
        Title
        Link
        Order
        Gradient
        Image {
          localFile {
            childImageSharp {
              fluid(maxHeight: 960) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        tags {
          Name
          id
        }
      }
    }

    allFile(filter: { dir: { glob: "**/landing" } }) {
      nodes {
        name
        publicURL
        extension
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }

    allHomePageJson {
      nodes {
        id
        pulledQuote {
          byline
          author
          text
        }
        contactUs {
          text
          buttonText
        }
        expertise {
          header
          tabs {
            header
            text
            columns {
              text
              paragraphImage
              alt
            }
          }
        }
        main {
          body
          header
          features {
            body
            header
            backgroundIcon
            paragraphImage
          }
        }
      }
    }
  }
`
