import React from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import { navigate } from 'gatsby'

const useStyles = makeStyles((theme) => ({
  searchInputBox: {
    width: '180px',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      width: '120px',
      position: 'absolute',
      top: '2px',
      left: '-110px',
    },
  },
}))

const SearchBox = () => {
  const theme = useTheme()
  const classes = useStyles(theme)

  const handleKeyUp = function (evt) {
    // "enter" key is 13

    // NOTE: if changing the method by which the search query is passed
    // to the search page, it also needs to be changed in the header
    // (separate handler attached to search icon)

    // use this if we want to use gatsby internals to pass the query
    // if (evt.which === 13 && evt.target.value) {
    //     navigate(`/search`, { state: { query: evt.target.value } })
    // }

    // use this if we want to pass the query using URL query params (linkable)
    if (evt.which === 13 && evt.target.value) {
      const query = `query=${encodeURIComponent(evt.target.value)}`
      navigate(`/search?${query}`)
    }
  }
  return <input className={classes.searchInputBox} placeholder="Search Input" type="text" onKeyUp={handleKeyUp} />
}

export default SearchBox
